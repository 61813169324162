






























































































































































































































import _ from 'lodash'
import moment from 'moment'
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

@Component({
  name: 'LessonDetails',
  components: {}
})
export default class LessonDetails extends Vue {
  valid = false
  dialogVisible = false
  dialogDelete = false
  menuStartDate = false
  menuEndDate = false
  classToEdit: any = null
  selectedMilestone = null
  selectedMilestoneId = null

  titleRules = [
    (v: any) => !!v || 'Name is required'
  ]

  stageRules = [
    (v: any) => !!v || 'Stage is required'
  ]

  milestoneRules = [
    (v: any) => !!v || 'Milestone is required'
  ]

  @Prop() private classItem!: any
  @Prop() private mode!: any

  get canAdd () {
    const classLimit = this.$store.getters.getConfig('classLimit', 2)
    if (classLimit) {
      return this.$store.state.currentProgram.classes.length < classLimit
    }
    return true
  }

  get canEdit () {
    return true
  }

  get titleText () {
    return this.mode === 'edit' ? 'Edit Class' : 'Add Class'
  }

  get limitTooltipText () {
    const classLimit = this.$store.getters.getConfig('classLimit', 2)
    if (classLimit) {
      return 'Sorry, you have reached the maximum number of classes'
    }
    return ''
  }

  get stages (): any {
    return this.$store.getters.getStages()
  }

  get milestones (): any {
    return this.$store.getters.getMilestones()
  }

  get stagesWithMilestones (): any {
    const items: any[] = this.$store.getters.getStagesWithMilestones() || []
    _.each(items, i => {
      i.selected = i.id === this.selectedMilestoneId
    })
    return items
  }

  get selectedClass () {
    return this.classItem
    // return this.mode === 'edit' ? this.classItem : {}
  }

  set selectedClass (value) {
    this.classItem = value
  }

  get currentMilestone () {
    if (this.classToEdit.milestone && this.classToEdit.milestone.id) {
      this.selectedMilestoneId = this.classToEdit.milestone.id
    }
    this.selectedMilestone = this.stagesWithMilestones.find((o: any) => o.id === this.selectedMilestoneId)
    return this.selectedMilestone
  }

  set currentMilestone (value: any) {
    this.classToEdit.stageId = value.stageId
    this.classToEdit.milestoneId = value.id
    const stageDefItem = this.stages.find((o: any) => o.id === this.classToEdit.stageId)
    const milestoneDefItem = this.milestones.find((o: any) => o.id === this.classToEdit.milestoneId)
    this.classToEdit.stage = stageDefItem
    this.classToEdit.milestone = milestoneDefItem
    this.selectedMilestone = value
  }

  get startDateFormatted () {
    return this.classToEdit.startDate ? moment(this.classToEdit.startDate).format('Do MMMM YYYY') : ''
  }

  get endDateFormatted () {
    return this.classToEdit.endDate ? moment(this.classToEdit.endDate).format('Do MMMM YYYY') : ''
  }

  get editButtonTip () {
    return this.mode !== 'edit' && !this.canAdd ? 'Class limit reached' : 'Select to Add a Class'
  }

  get defaultClassTitle () {
    return `Class ${this.$store.state.currentProgram.classes.length + 1}`
  }

  @Watch('dialogVisible')
  dialogVisibleChanged (newVal: boolean) {
    if (newVal) {
      // Here you would put something to happen when dialog opens up
      // console.log('Dialog was opened!')
      this.classToEdit = _.cloneDeep(this.selectedClass)
      this.selectedMilestoneId = this.classToEdit.milestone
      if (this.mode === 'add') {
        this.classToEdit.title = this.defaultClassTitle
      }
    } else {
      this.$data.classToEdit = null
    }
  }

  save () {
    const form = (this.$refs.classForm) as any
    form.validate()
    if (!this.valid) {
      return
    }
    // let newId = null
    if (!this.$data.classToEdit.title || this.$data.classToEdit.title === '') {
      this.$data.classToEdit.title = this.defaultClassTitle
    }
    if (this.mode === 'add') {
      this.$store.commit('createClassInCurrentProgram', this.$data.classToEdit)
    } else {
      this.$store.commit('updateClass', this.$data.classToEdit)
    }
    this.$data.dialogVisible = false
    if (this.mode === 'add') {
      this.$emit('class-added')
    }
  }

  deleteClass () {
    this.$store.commit('deleteClass', this.selectedClass)
    this.$data.dialogVisible = false
    this.$router.replace('/classes')
  }

  mounted () {
    // debugger
    // this.selectedMilestoneId = this.classToEdit.milestone
  }
}
