var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.mode === 'add')?_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.canAdd,"title":_vm.editButtonTip,"color":"primary","rounded":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Class ")],1):_vm._e(),(_vm.mode === 'add' && !_vm.canAdd)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 text--secondary",attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.limitTooltipText))])]):_vm._e(),(_vm.mode === 'edit')?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"disabled":!_vm.canEdit,"x-small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Edit Details ")]):_vm._e()]}}]),model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[(_vm.classToEdit)?_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.titleText))])]),_c('v-card-text',[_c('v-form',{ref:"classForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":_vm.stagesWithMilestones,"item-disabled":"disabled","item-text":"title","item-value":"id","label":"Milestone*","rules":_vm.milestoneRules,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,false,1825714625),model:{value:(_vm.currentMilestone),callback:function ($$v) {_vm.currentMilestone=$$v},expression:"currentMilestone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","placeholder":"e.g. Class 1","xxrules":_vm.titleRules},model:{value:(_vm.classToEdit.title),callback:function ($$v) {_vm.$set(_vm.classToEdit, "title", $$v)},expression:"classToEdit.title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"menuStartDate",attrs:{"close-on-content-click":false,"return-value":_vm.classToEdit.startDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.classToEdit, "startDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.classToEdit, "startDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.startDateFormatted,"v-model":_vm.classToEdit.startDate,"label":"Start Date","prepend-icon":"mdi-calendar","readonly":"","required":""}},'v-text-field',attrs,false),on))]}}],null,false,560007839),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){return _vm.$refs.menuStartDate.save(_vm.classToEdit.startDate)}},model:{value:(_vm.classToEdit.startDate),callback:function ($$v) {_vm.$set(_vm.classToEdit, "startDate", $$v)},expression:"classToEdit.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"menuEndDate",attrs:{"close-on-content-click":false,"return-value":_vm.classToEdit.endDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.classToEdit, "endDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.classToEdit, "endDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.endDateFormatted,"v-model":_vm.classToEdit.endDate,"label":"End Date","prepend-icon":"mdi-calendar","readonly":"","required":""}},'v-text-field',attrs,false),on))]}}],null,false,2000964144),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){return _vm.$refs.menuEndDate.save(_vm.classToEdit.endDate)}},model:{value:(_vm.classToEdit.endDate),callback:function ($$v) {_vm.$set(_vm.classToEdit, "endDate", $$v)},expression:"classToEdit.endDate"}})],1)],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1)],1),_c('v-card-actions',[[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.mode === 'edit')?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","rounded":""},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_vm._v(" Delete ")]):_vm._e()]}}],null,false,75434140),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline error white--text"},[_vm._v(" Are you sure you want to delete? ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v("If you continue, the "),_c('strong',[_vm._v(_vm._s(_vm.classToEdit.title))]),_vm._v(" class will be deleted.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error","rounded":""},on:{"click":function($event){return _vm.deleteClass()}}},[_vm._v(" Continue and delete ")])],1)],1)],1)],_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","rounded":"","depressed":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }