

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import ClassEdit from '@/components/ClassEdit.vue'
import LessonEdit from '@/components/LessonEdit.vue'
import LessonCard from '@/components/LessonCard.vue'
import draggable from 'vuedraggable'
import _ from 'lodash'
import moment from 'moment'

@Component({
  name: 'ClassDetails',
  components: {
    draggable,
    ClassEdit,
    LessonEdit,
    LessonCard
  },
  data: () => ({
    //
  }),
  methods: {
    //
  }
})
export default class ClassDetails extends Vue {
  drag = false

  // @Prop() private currentClass!: any

  get welcomeText () {
    const defaultText = 'Add Lessons to your Class by selecting the \'Add Lesson\' button. Edit Lessons  when you have created them by clicking on the Lesson tile. To edit or delete a lesson click select the lesson tile, then select \'Edit Details\'.'
    return this.$store.getters.getConfig('lessonIntroText', defaultText)
  }

  get stage (): any {
    return this.$store.getters.getDevStageById(this.currentClass.stageId) || {}
  }

  get milestone (): any {
    return this.$store.getters.getDevMilestoneById(this.currentClass.milestoneId) || {}
  }

  get startDateFormatted () {
    return this.currentClass.startDate ? moment(this.currentClass.startDate).format('dddd, Do MMMM YYYY') : ''
  }

  get endDateFormatted () {
    return this.currentClass.endDate ? moment(this.currentClass.endDate).format('dddd, Do MMMM YYYY') : ''
  }

  get stageAndMilestoneTitle (): string {
    return this.$store.getters.getStageAndMilestoneTitleOfCurrentClass()
  }

  get currentClass (): any {
    return this.$store.state.currentClass
  }

  get editClass (): any {
    const newItem = _.cloneDeep(this.currentClass)
    return newItem
  }

  get canAdd () {
    if (this.$store.state.config.lessonLimit) {
      return this.currentClass.lessons.length < this.$store.state.config.lessonLimit
    }
    return true
  }

  lessonAdded () {
    this.$router.push(`/class/${this.currentClass.id}/lesson/${this.currentClass.lessons.length}`)
  }

  // Start Drag code
  get dragOptions () {
    return {
      animation: 200,
      group: 'lessons',
      disabled: false,
      ghostClass: 'ghost'
    }
  }

  private endDrag (value: any) {
    _.each(this.currentClass.lessons, (l, i) => {
      l.id = i + 1
    })
  }

  private getComponentData () {
    return {
      on: {
        end: this.endDrag
      },
      attrs: {
        wrap: true
      },
      props: {
        value: this.currentClass.lessons
      }
    }
  }
  // End Drag code
}
