




































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ActivityListItem from '@/components/ActivityListItem.vue'

@Component({
  name: 'LessonCard',
  components: {
    ActivityListItem
  }
})
export default class LessonCard extends Vue {
  dialog = false
  @Prop() private lesson!: any

  get currentClass (): any {
    return this.$store.state.currentClass
  }

  get focusSkill (): any {
    return this.$store.getters.getSkillById(this.lesson.focusSkill) || {}
  }

  get calculatedMinutes () {
    let mins = 0
    if (this.lesson.activities) {
      mins = this.lesson.activities.reduce(function (a: number, b: any) {
        return a + b.duration
      }, 0)
    }
    return mins
  }

  get minutesClass () {
    const limit = this.$store.getters.getConfig('lessonTotalMinutesLimit', 30)
    if (limit && this.calculatedMinutes > limit) {
      return 'error--text'
    }
    return ''
  }

  get showStartDate () {
    return this.$store.getters.getConfig('lessonIncludeStartDate', false)
  }

  get showTitle () {
    return this.$store.getters.getConfig('lessonIncludeTitle', false)
  }

  deleteLesson (item: any) {
    this.$store.commit('deleteLesson', item)
    this.$data.dialog = false
  }
}
