













import { Component, Prop, Vue } from 'vue-property-decorator'
import ClassDetails from '@/components/ClassDetails.vue'

@Component({
  components: {
    ClassDetails
  },
  data: () => ({
    // mini: true
  })
})
export default class Class extends Vue {
  @Prop() private programId!: number
  @Prop() private classId!: number
  // @Prop() private mini!: boolean

  get currentClass (): any {
    return this.$store.state.currentClass
  }

  created () {
    this.fetchData()
  }

  beforeRouteUpdate (to: string, from: string, next: Function) {
    this.fetchData()
    next()
  }

  public fetchData (): void {
    // this.error = null
    // this.lesson = this.$store.state.currentLesson
    // this.loading = true
    if (!this.$store.state.dataLoaded) {
      return
    }

    this.$store.commit(
      'setCurrentProgram',
      this.$store.getters.getPlannerDataByProgramId(this.$store.state.programId)
    )
    this.$store.commit(
      'setCurrentClass',
      this.$store.getters.getClassById(this.$props.classId)
    )
    // replace `getLesson` with your data fetching util / API wrapper
    // getLesson(fetchedId, (err, lesson) => {
    // make sure this request is the last one we did, discard otherwise
    // if (this.$route.params.id !== fetchedId) return
    // this.loading = false
    /* if (err) {
        this.error = err.toString()
      } else {
        this.lesson = lesson
      } */
    // })
  }
}
